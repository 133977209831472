import { defineComponent, onMounted, ref, } from 'vue';
import QrView from '@/components/QrView.vue';
import { useI18n } from 'vue-i18n';
import Backend from '@/core/Services/Backend';
import useQrResult from '@/utils/useQrResult';
export default defineComponent({
    name: 'ContractQr',
    components: {
        QrView,
    },
    props: {
        addressList: {
            type: Array,
            required: true,
        },
        chainType: {
            type: String,
            required: true,
        },
        hash: {
            type: String,
            required: true,
        },
        precision: {
            type: Number,
            required: true,
        },
        gasLimit: {
            type: String,
            required: true,
        },
        gasPrice: {
            type: String,
            required: true,
        },
    },
    emits: {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        success: (hash) => true,
        close: () => true,
    },
    setup(props, { emit }) {
        const { t } = useI18n();
        const qrCode = ref('');
        const qrId = ref('');
        const { startFetchResult } = useQrResult((hash) => {
            emit('success', hash);
        });
        onMounted(async () => {
            const { id, qr } = await Backend.getDropQr(props.chainType, props.hash, props.addressList, props.precision, props.gasLimit, props.gasPrice);
            qrId.value = id;
            qrCode.value = qr;
            startFetchResult(id);
        });
        const emitClose = () => {
            emit('close');
        };
        return {
            t,
            qrCode,
            emitClose,
        };
    },
});
